.scheduleHeader {
  height: 58px;
  width: 100%;
  padding: 0px 10px;
  border: 1px solid #404040;
  border-radius: 5px;
  background-color: #404040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 20px;
}
.scheduleBoxOne {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 90%;
}
.iconHolder {
  width: 28px;
  height: 28px;
  border-radius: 50%;
  background-color: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.scheduleDate p {
  font-family: Manrope;
  font-weight: 300;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}
.scheduleDate span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  color: #ffffff;
  margin: 0;
}
.trainingDetailBox {
  height: 95px;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #cbcbcb;
  background: #ffffff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}
.trainingInfo h1 {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.trainingInfo span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.trainingInfo p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  line-height: 24px;
  color: #000000;
  margin: 0;
}
.trainingInfo h2 {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  line-height: 18px;
  color: #ff4300;
  margin: 0;
}
.separation {
  font-family: Manrope !important;
  font-weight: 500 !important;
  font-size: 14px !important;
  line-height: 18px !important;
  color: #787878 !important;
}
.trainingOption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  padding: 18px 0px;
}
.trainingInfo img {
  height: 24px;
  width: 24px;
}
.addWorkoutBox {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  border: 1px solid #b5b5b5;
  background: #ffffff;
  margin: 10px 0px 20px 0px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 15px;
}
.addWorkoutBox p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #ff4300;
  margin: 0;
}
.addWorkoutBox span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  color: #000000;
  margin: 0;
}
.addWorkoutBox button {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addWorkoutBox button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
.addWorkoutBox button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
  margin: 0;
}
