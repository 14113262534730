.grpBox {
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  margin-bottom: 20px;
  padding: 10px 10px;
}
.detailBox {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.grpDetail h1 {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 600;
  line-height: 17px;
  letter-spacing: 0.26px;
  color: #000000;
  margin: 0;
}
.grpDetail h2 {
  font-family: Manrope;
  font-size: 13px;
  font-weight: 300;
  line-height: 17px;
  letter-spacing: 0.26;
  color: #000000;
  margin: 0;
}
.grpDetail p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.24;
  color: #000000;
  margin: 0;
}
.grpDetail span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 12px;
  letter-spacing: 0.24;
  color: #ff4300;
  margin: 0;
  cursor: pointer;
}
.noDataFound p {
  font-family: Manrope;
  font-weight: 700;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.noDataFound span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  line-height: 23px;
  color: #212529;
  margin: 0;
}
.grpOption {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 52px;
}
.grpOption img {
  width: 24px;
  height: 24px;
  cursor: pointer;
}

.addGrpSection {
  height: 60px;
  width: 100%;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 20px;
}
.addGrpSection p {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: #ff4300;
}
.addGrpSection span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 14px;
  margin: 0;
  color: #000000;
}
.addGrpSection button {
  width: 110px;
  height: 34px;
  border-radius: 4px;
  background: #ff4300;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.addGrpSection button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  margin: 0;
  color: #ffffff;
}
.addGrpSection button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  margin: 0;
  color: #ffffff;
}
.memberBox {
  height: 46px;
  width: 85%;
  border-radius: 4px;
  background: #e9e9e9;
  margin-top: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0px 10px 0px 0px;
}
.memberImg {
  width: 46px;
  height: 46px;
  border-radius: 50%;
}
.memberImg img {
  width: 46px !important;
  height: 46px !important;
  border-radius: 50%;
}
.memberBoxInfo {
  height: 46px;
  display: flex;
  align-items: center;
  /* width: 152px; */
  justify-content: space-between;
}
.memberBoxInfo p {
  font-family: Manrope;
  font-weight: 300;
  font-size: 13px;
  color: #223350;
  margin: 0px 0px 0px 10px;
}
.memberBoxInfo span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 13px;
  color: #223350;
  margin: 0;
}
.memberBox img {
  height: 24px;
  width: 24px;
}
.closeButton {
  width: 81px;
  height: 34px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #000000;
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  margin-top: 15px;
}

.inviteormore {
  width: 100%;
  height: 60px;
  background: #ffffff;
  margin-top: 25px;
  padding: 10px 20px;
  display: flex;
  justify-content: flex-end;
  border: 1px solid #b5b5b5;
  border-radius: 0px 0px 5px 5px;
  align-items: center;
}

.inviteormore button {
  width: 134px;
  height: 34px;
  background: #ff4300;
  border-radius: 4px;
  border: 1px solid #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.inviteormore button p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}
.inviteormore button span {
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  letter-spacing: 0.4px;
  color: #ffffff;
  margin: 0;
}

.noActiveGrp P {
  opacity: 1;
  color: rgba(33, 37, 41, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  /* line-height: 46px; */
  margin: 0px 0px 15px 0px;
}
.noActiveGrp span {
  opacity: 1;
  color: #ff4300;
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  line-height: 23px;
  margin: 0;
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .inviteormore {
    height: auto;
  }
  .membersCount {
    flex-direction: column;
    height: 105px;
  }
}
