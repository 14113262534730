.workoutSection {
  min-height: 52px;
  width: 100%;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  border-radius: 4px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px;
  margin-bottom: 10px;
}
.workoutSection p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  line-height: 24px;
  margin: 0;
  width: 90%;
}
.iconHolder {
  display: flex;
  justify-content: space-between;
  width: 55px;
}
.iconHolder img {
  height: 24px;
  width: 24px;
}
.btnBox {
  width: 100%;
  height: 60px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #b5b5b5;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px 10px;
}
.btnBox button {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
}
.btnBox button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: "Manrope-Regular";
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.btnBox button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
.btnBox p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.btnBox span {
  opacity: 1;
  color: #000000;
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
