.inviteLabel {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  color: #000000;
  margin: 0;
}
.CompanySection {
  width: 177px;
  height: 48px;
  border-radius: 5px;
  border: 1px solid #231f20;
  background: #231f20;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 6px 15px;
  margin-top: 7px;
}
.CompanySection img {
  width: 32px;
  height: 32px;
}
.CompanyInfo {
  display: flex;
  flex-direction: column;
}
.CompanyInfo h1 {
  margin: 0;
  font-size: 13px;
  color: #ffffff;
  font-weight: 700;
}
.CompanyInfo p {
  margin: 0;
  color: #ffffff;
  font-weight: 600;
  font-size: 10px;
}
.searchBox {
  position: relative;
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 13px 16px;
}
.searchBox p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin: 0;
  line-height: 18px;
}
.searchBox span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.searchBox input {
  width: 466px;
  height: 58px;
  border-radius: 4px;
  border: 1px solid #f5f5f5;
  background-color: #f5f5f5;
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
  padding: 0px 70px;
  margin-top: 10px;
}
.searchBox input::placeholder {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #000000;
}
.profleImg img {
  position: absolute;
  width: 46px;
  height: 46px;
  top: 47px;
  left: 25px;
}
.searchBox input:focus {
  border: none;
  outline: none;
  box-shadow: none;
}
.searchResult {
  width: 466px;
  height: 58px;
  border-radius: 4px;
  border: 1px solid #404040;
  background: #404040;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 13px;
  padding: 0px 7px;
}
.searchInfo {
  display: flex;
  justify-content: space-between;
}
.searchInfo img {
  height: 46px;
  width: 46px;
}
.searchResult button {
  width: 86px;
  height: 40px;
  border-radius: 4.32px;
  border: 1px solid #ff4300;
  background: #ff4300;
  font-family: Manrope;
  font-weight: 600;
  font-size: 17px;
  letter-spacing: 0.48px;
  color: #ffffff;
}
.userDetail {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  margin: 5px 10px;
}
.userDetail h1 {
  font-family: Manrope;
  font-weight: 600;
  font-size: 15px;
  color: #ffffff;
  margin: 0;
}
.userDetail p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 10px;
  color: #ffffff;
  margin: 0;
}
.pendingInvitesBox {
  width: 100%;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background: #ffffff;
  margin-top: 30px;
  padding: 20px;
}
.pendingInvitesBoxLabel h1 {
  font-family: Manrope;
  font-weight: 400;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.pendingInvitesBoxLabel span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 18px;
  color: #000000;
  margin: 0;
}
.pendingInvitesBoxLabel p {
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #747474;
  margin: 5px 0px 0px 0px;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .searchBox input {
    width: 100%;
  }
  .searchResult {
    width: 100%;
  }
}
