.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.brandHead {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  background: #404040;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.brandHead p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.currentSubscripton p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  margin: 0;
  line-height: 10px;
}
.memberCount p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
}
.upgrade p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  margin: 0;
}
.manageSubscription p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.manageButton {
  width: 275px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  margin-top: 20px;
}
.manageButton p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.48px;
  margin: 0;
}
.manageButton span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 500;
  letter-spacing: 1.48px;
  margin: 0;
}
