.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.brandHead {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  background: #404040;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.brandHead p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.brandLabel {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 400;
  letter-spacing: 0.46px;
  margin: 0;
  margin-bottom: 0px;
}
.brandDescription {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.32px;
  margin: 0;
}
.brandLabel span {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 700;
  letter-spacing: 0.46px;
  margin: 0;
}
.formInput {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
}
.formInput label p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.formInput label span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.formInput input {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  padding: 0px 10px;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.formInput input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.formInput input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectCountry {
  left: 57px;
  position: absolute;
  top: 37px;
}
.selectCountry2 {
  left: 57px;
  position: absolute;
  top: 37px;
}
.physicalAddress {
  display: flex;
}
.physicalAddress {
  width: 100%;
}
.checkBoxArea input {
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  background-color: #eaeaea;
}
.checkBoxArea input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #979797;
  background-color: #eaeaea;
}
.checkBoxArea input:checked {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}
.checkBoxArea label p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.checkBoxArea label span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.locationRequired p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.locationRequired span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0;
}
.selectInput {
  min-height: 75px;
  margin-top: 10px;
  position: relative;
  width: 278px;
}
.selectInput label p {
  opacity: 1;
  color: #394858;
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
  margin: 0;
}
.selectInput select {
  width: 278px;
  height: 38px;
  border-radius: 4px;
  border: 1px solid #c4c4c4;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(236, 236, 236, 1) 100%
  );
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
}

.selectInput svg {
  position: absolute;
  top: 35px;
  right: 15px;
  font-size: 30px;
}

.selectInput input {
  height: 38px;
  width: 278px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectInput input::placeholder {
  opacity: 1;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectInput input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(108, 117, 125, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.selectImgSection p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 500;
  margin: 0px 0px 5px 0px;
}
.selectImgSection h1 {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  margin: 0px 0px 20px 0px;
}
.selectImgSection button {
  width: 131px;
  height: 34px;
  border-radius: 3.6px;
  border: 1px solid #ff4300;
  background: #ff4300;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.addButton input {
  width: 16px;
  height: 16px;
  border: 1px solid #979797;
  background-color: #eaeaea;
}
.addButton input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #979797;
  background-color: #eaeaea;
}
.addButton input:checked {
  background-color: #ff4300;
  border: 1px solid #ff4300;
}
.addButton label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  margin: 0;
}
.physicalAddress p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  margin: 0;
}
.saveChanges {
  height: 60px;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  background: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 15px;
  margin-top: 20px;
}
.saveChanges button {
  width: 131px;
  height: 34px;
  border-radius: 3.6px;
  border: 1px solid #ff4300;
  background: #ff4300;
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
}
.mapDescription {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  margin: 10px 0px 0px 0px;
}
.previewMembershipImgBox {
  max-width: 131px;
  max-height: 131px;
  position: relative;
  margin-top: 20px;
}
.previewImg {
  height: 100%;
  object-fit: contain;
  width: 100%;
}
.removeImg {
  width: 20px;
  height: 20px;
  position: absolute;
  left: 111px;
  top: 0px;
  cursor: pointer;
}

@media screen and (min-width: 320px) and (max-width: 364px) {
  .selectCountry {
    left: 57px;
    position: absolute;
    top: 61px;
  }
}
@media screen and (min-width: 320px) and (max-width: 424px) {
  .selectCountry2 {
    left: 57px;
    position: absolute;
    top: 61px;
  }
}
@media screen and (min-width: 320px) and (max-width: 575px) {
  .selectInput {
    width: 100%;
  }
  .selectInput select {
    width: 100%;
  }
  .selectInput svg {
    position: absolute;
    top: 35px;
    right: 15px;
    font-size: 30px;
  }
  .selectInput input {
    width: 100%;
  }
}
