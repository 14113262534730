.MainBox {
  /* height: 437px; */
  width: 100%;
  margin-top: 15px;
  margin-bottom: 15px;
  border-radius: 5px;
  background: #ffffff;
  border: 1px solid #ced4da;
}
.headingBox {
  height: 52px;
  width: 100%;
  background: #ececec;
  border-bottom: 1px solid #ced4da;
  border-radius: 5px 5px 0px 0px;
  display: flex;
  align-items: center;
  padding: 0px 15px;
}
.headingBox p {
  font-family: Manrope;
  font-weight: 400;
  font-size: 16px;
  margin: 0;
  color: #000000;
}
.headingBox span {
  font-family: Manrope;
  font-weight: 600;
  font-size: 16px;
  margin: 0;
  color: #000000;
}
.inputArea {
  width: 100%;
  padding: 15px;
}
.inputText {
  margin-top: 15px;
}

.inputText label {
  font-family: Manrope;
  font-weight: 500;
  font-size: 15px;
  color: #394858;
}
.inputText input {
  height: 38px;
  width: 100%;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
}
.inputText input::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}
.inputText textarea {
  height: 117px;
  width: 100%;
  border-radius: 4px;
  background: #f6f6f6;
  border: 1px solid #c4c4c4;
}
.inputText textarea::placeholder {
  font-family: Manrope;
  font-weight: 400;
  font-size: 15px;
  color: #6c757d;
}
.btnArea {
  height: 58px;
  width: 100%;
  border-radius: 0px 0px 5px 5px;
  border-top: #ced4da;
  background: #ececec;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 15px;
}
.cancelBtn {
  width: 81px;
  height: 34px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #000000;
  font-family: Manrope;
  font-weight: 500;
  font-size: 14px;
  color: #000000;
  margin-right: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.cancelAndDeleteBtn {
  display: flex;
}
.addBtn {
  width: 131px;
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 0px;
}
.addBtn p {
  margin: 0;
  font-family: Manrope;
  font-weight: 400;
  font-size: 14px;
  color: #ffffff;
}
.addBtn span {
  margin: 0;
  font-family: Manrope;
  font-weight: 700;
  font-size: 14px;
  color: #ffffff;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .btnArea {
    height: auto;
    flex-direction: column;
  }
  .cancelAndDeleteBtn {
    display: flex;
    width: 100%;
    justify-content: space-between;
  }
  .addBtn {
    width: 100%;
    margin-top: 10px;
  }
  .cancelBtn {
    width: 100%;
  }
}
