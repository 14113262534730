.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.brandHead {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  background: #404040;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.brandHead p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.tabHead p {
  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  letter-spacing: 0.46px;
  margin: 0;
  line-height: 10px;
}
.formInput {
  position: relative;
  margin-bottom: 10px;
}
.formInput label {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 14.5px;
  font-weight: 500;
}
.formInput input {
  width: 300px;
  height: 38px;
  border-radius: 4px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  opacity: 1;

  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.formInput input::placeholder {
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.formInput input:focus {
  box-shadow: none;
  outline: none;
  border: 1px solid #c4c4c4;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
}
.formInput svg {
  position: absolute;
  top: 40px;
  left: 268px;
  font-size: 20px;
}
.resetOption {
  height: 34px;
  border-radius: 4px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 25px;
}
.resetOption p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.resetOption span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 700;
  margin: 0;
}
