.Sidebar {
  background: #000000;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 240px;
  box-shadow: 0px 0px 15px 0px #0000000d;
  height: 100vh;
  overflow-y: auto;
  overflow-x: hidden;
  border-radius: 0px;
}
.Sidebar::-webkit-scrollbar {
  width: 2px;
}

/* Track */
.Sidebar::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
.Sidebar::-webkit-scrollbar-thumb {
  background: #888;
}

.NavItems {
  margin-top: 145px;
  background: none;
  display: flex;
  flex-direction: column !important;
  align-items: center;
  width: 240px;
  color: #000000;
}

.Navlink {
  text-decoration: none;
  font-family: Manrope;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 190px;
  height: 46px;
  border: 1.2px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}
.NavlinkButton {
  text-decoration: none;
  font-size: 14px;
  line-height: 22px;
  font-weight: 500;
  display: flex;
  align-items: center;
  color: #ffffff;
  width: 190px;
  height: 46px;
  border: 1.2px solid #ffffff;
  border-radius: 5px;
  margin-bottom: 10px;
}

.SideBarHeader {
  /* border: 1px solid white; */
  position: fixed;
  display: flex;
  justify-content: center;
  /* margin-top: 26px; */
  margin-bottom: 23px;
  width: 240px;
  height: 120px;
  background-color: #000000;
}
.SideBarHeader img {
  height: 90px;
  width: 195px;
  margin-top: 25px;
  border-radius: 4px;
  object-fit: cover;
}

@media screen and (max-width: 991px) {
  .TopNavLogo {
    display: none;
    /* display: block;
            cursor: pointer; */
  }
}

@media only screen and (max-width: 991px) {
  .Sidebar {
    /* position: absolute; */
    background: #000000;
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 43px;
    width: 45px;
    box-shadow: 0px 0px 15px 0px #0000000d;
    overflow-y: auto;
    overflow-x: hidden;
    border-radius: 10px;
  }
  .NavItems {
    margin-top: 145px;
    background: #000000;
    display: flex;
    flex-direction: column !important;
    align-items: center;
    width: 260px;
    color: #000000;
    height: auto;
    overflow: scroll;
  }

  .SideBarHeader {
    /* border: 1px solid white; */
    position: none;
    display: flex;
    justify-content: center;
    margin-bottom: 23px;
    width: 261px;
    height: 120px;
    background-color: #000000;
  }
  .SideBarHeader img {
    height: 90px;
    width: 195px;
    margin-top: 25px;
  }
}
