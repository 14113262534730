.heading {
  font-family: Manrope;
  font-size: 20px;
  font-weight: 600;
  line-height: 28.69px;
  letter-spacing: 0.47999998927116394px;
  text-align: left;
  color: #000000;
  margin: 0;
}
.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.gridView {
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 70px;
  border: 1px solid #c4c4c4;
  border-radius: 4px;
  background: #ffffff;
}
.gridView p {
  margin: 0;
  font-family: Manrope;
  color: #6c757d;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
}
.gridView h1 {
  font-family: Manrope;
  font-size: 30px;
  font-weight: 600;
  line-height: 24px;
  text-align: center;
  color: #6c757d;
}
.addThings {
  margin-top: 20px;
  display: flex;
  width: 100%;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40px;
  background: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 4px;
}
.addThings p {
  margin: 0;
  font-family: Manrope;
  color: #ffffff;
  font-size: 12px;
  font-weight: 400;
  text-align: center;
  line-height: 24px;
}
.limit {
  font-family: Manrope;
  font-size: 26px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: #000000;
  margin-top: 50px;
}
.upgrade {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 700;
  line-height: 21.86px;
  text-align: left;
  color: #394858;
  margin: 20px 0px 0px 0px;
}
.manage {
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  line-height: 21.86px;
  text-align: left;
  color: #394858;
  margin: 5px 0px 0px 0px;
}
.subscribeBtn {
  width: 275px;
  height: 48px;
  background: #ff4300;
  border: 1px solid #ff4300;
  border-radius: 4px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.subscribeBtn p {
  font-family: Manrope;
  font-weight: 900;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 1.48;
  margin: 0;
  color: #ffffff;
}
.subscribeBtn span {
  font-family: Manrope;
  font-weight: 500;
  font-size: 17px;
  line-height: 20px;
  letter-spacing: 1.48;
  margin: 0;
  color: #ffffff;
}
