.devider {
  width: 100%;
  height: 1px;
  background: #c1c1c1;
  margin: 20px 0px;
}
.brandHead {
  width: 100%;
  height: 58px;
  border-radius: 5px;
  background: #404040;
  display: flex;
  align-items: center;
  padding: 0px 20px;
}
.brandHead p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 600;
  margin: 0;
}
.tabBorder {
  border-bottom: 1px solid #c1c1c1;
}
.brandHeading h3 {
  color: #000;
  font-size: 20px;
  font-weight: 600;
  padding: 15px 5px;
  border-bottom: 1px solid #c1c1c1;
  margin-top: -20px;
  letter-spacing: 0.46px;
}

.btnStyleMatch p {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0px;
}

.btnStyleMatch span {
  color: #394858bf;
  font-size: 16px;
  font-weight: 500;
}
.dispalyColor {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  /* height: 100px; */
}
.clorInput .bottomHighlight {
  color: #ff4300;
  margin-bottom: 10px;
  margin-top: 4px;
  font-size: 14px;
  font-weight: 600;
}
.exbtnLabel p {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
  margin-bottom: 3px;
}

.exbtnLabel span {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
}
.colourInput {
  display: flex;
}
.inputBox {
  margin-left: 12px;
}

.inputBox input {
  width: 122px;
  height: 38px;
  background: #ffffff;
  border: 1px solid #c4c4c4;
  border-radius: 4px;

  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 15px;
  font-weight: 500;
  padding: 0px 10px;
}
.prefillColor {
  height: 38px;
  width: 38px;
  border-radius: 4px;
  border: 1px solid #ced4da;
}
.inputBox input:focus {
  box-shadow: none;
  border: 1px solid #c4c4c4;
  outline: none;
}
.colourInput {
  display: flex;
}
.bottomHighlight {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}
.bottomBox .brandBgText p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px !important;
  font-weight: 600 !important;
  margin: 0;
}
.exBtnBtn button {
  background-color: #ff4300;
  border-radius: 4px;
  border: 1px solid #fff;
  width: 226px;
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.exBtnBtn button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.48px;
}

.exBtnBtn button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1.48px;
}
.exbtnLabel p {
  font-size: 16px;
  font-weight: 400;
  font-family: Manrope;
  color: #394858;
  margin-bottom: 0px;
}

.exbtnLabel span {
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
  color: #394858;
}
.exBtnBtn button {
  text-align: center;
  background-color: #ff4300;
  border-radius: 7px;
  border: 1px solid #fff;
  width: 226px;
  height: 48px;
  margin-bottom: 5px;
}

.exBtnBtn button p {
  font-size: 17px;
  font-weight: 900;
  color: #fff;
  margin-bottom: 0px;
}
.exPara {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 5px 0px 0px 0px;
}
.exBtn {
  color: #fff;
  margin: 0;
}
.exBtnBtn button span {
  font-size: 17px;
  font-weight: 300;
}
.bottomBox P {
  color: #394858;
  font-size: 16px !important;
  font-weight: 400 !important;
  font-family: Manrope;
  margin: 0;
}

.bottomBox span {
  color: #394858;
  font-size: 16px !important;
  font-weight: 600 !important;
  font-family: Manrope;
}
.resetDefault p {
  color: #000;
  font-size: 14px;
  font-weight: 900;
  font-family: Manrope;
}

.resetDefault span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: Manrope;
}

.resetDefault {
  margin-top: 20px;
  border-bottom: 1px solid #c1c1c1;
  cursor: pointer;
}
.bottomText {
  margin-top: 20px;
}

.bottomText h5 {
  font-size: 14px;
  font-weight: 500;
  color: #000;
  font-family: Manrope;
  margin: 5px 0px 15px 0px;
}

.bottomText p {
  font-size: 16px;
  font-weight: 600;
  color: #394858;
  font-family: Manrope;
  margin: 0;
}

.bottomText span {
  font-size: 16px;
  font-weight: 400;
  color: #394858;
  font-family: Manrope;
}
.uploadLabel {
  text-align: center;
  padding: 10px 30px;
  background-color: #ff4300;
  border-radius: 7px;
  color: #fff;
  border: 1px solid #ff4300;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 20px;
  font-family: Manrope;
}

.deletBtn button {
  width: 94px;
  height: 34px;
  border-radius: 3.6px;
  border: 1px solid #000000;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;

  opacity: 1;
  color: rgba(0, 0, 0, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
}
.deletBtn {
  margin-left: 15px;
}

.deletBtn svg {
  font-size: 18px;
  font-weight: 600;
  font-family: Manrope;
}
.btnSection {
  display: flex;
}
.saveBtnSection {
  height: 60px;
  width: 100%;
  border-radius: 0px 0px 4px 4px;
  background-color: #ffffff;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 0px 15px;
}

.saveChanges button {
  width: 106px;
  height: 34px;
  border-radius: 3.6px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  align-items: center;
  justify-content: center;

  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.4px;
}

.tabBox {
  height: 85px;
  width: 100%;
  display: flex;
  border: 1px solid #c1c1c1;
  background-color: #404040;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
}

.menuItem {
  display: flex;
  justify-content: space-evenly;
  width: 100%;
}

.menuItem svg {
  color: #fff;
  font-size: 25px;
  font-weight: 600;
}

.calendersvg {
  background-color: #ff4300;
  border-radius: 50px;
  padding: 8px 10px;
  margin: 0;
}

.calendersvgs {
  background-color: #404040;
  border-radius: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
}

.calendersvg svg {
  color: #fff;
  font-size: 25px !important;
  font-weight: 600;
}

.resetDefault p {
  color: #000;
  font-size: 14px;
  font-weight: 900;
  font-family: Manrope;
  margin: 23px 0px;
}

.resetDefault span {
  color: #000;
  font-size: 14px;
  font-weight: 400;
  font-family: Manrope;
}

.resetDefault {
  margin-top: 20px;
  border-bottom: 1px solid #c1c1c1;
  cursor: pointer;
}

.btnLabel p {
  color: #394858;
  font-size: 16px;
  font-weight: 400;
  font-family: Manrope;
  margin: 0;
}
.btnLabel span {
  color: #394858;
  font-size: 16px;
  font-weight: 600;
  font-family: Manrope;
}
.resetColor p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 15px 0px 15px 0px;
}
.resetColor span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
}

.colorBtn button {
  width: 226px;
  height: 48px;
  border-radius: 4px;
  border: 1px solid #ffffff;
  background: #ffffff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.colorBtn button p {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 900;
  letter-spacing: 1.48px;
  margin: 0;
}
.colorBtn button span {
  opacity: 1;
  color: rgba(255, 67, 0, 1);
  font-family: Manrope;
  font-size: 17px;
  font-weight: 300;
  letter-spacing: 1.48px;
}

.tabbarText p {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 400;
  margin: 0px 0px 5px 0px;
}
.tabbarText span {
  opacity: 1;
  color: rgba(57, 72, 88, 1);
  font-family: Manrope;
  font-size: 16px;
  font-weight: 600;
  margin: 0;
}
.uploadBox button {
  width: 131px;
  height: 34px;
  border-radius: 3.6px;
  border: 1px solid #ff4300;
  background: #ff4300;
  display: flex;
  justify-content: center;
  align-items: center;
}
.uploadBox button p {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 400;
  margin: 0;
}
.uploadBox button span {
  opacity: 1;
  color: rgba(255, 255, 255, 1);
  font-family: Manrope;
  font-size: 14px;
  font-weight: 600;
  margin: 0;
}

.previewImgBox {
  width: 270px;
  height: 110px;
  position: relative;
  border-radius: 6.32px;
  margin-top: 20px;
}
.previewImg {
  width: 270px;
  height: 110px;
  border-radius: 6.32px;
}
.removeImg {
  position: absolute;
  width: 24px;
  height: 24px;
  right: 0;
  cursor: pointer;
}
.errorMsg {
  width: 100% !important;
  font-size: 0.875em !important;
  color: red !important;
  margin: -10px 0px 0px 0px !important;
}
.errorMsgs p {
  width: 100% !important;
  font-size: 0.875em !important;
  color: red !important;
  margin: 0px 0px 0px 0px !important;
}

@media screen and (min-width: 320px) and (max-width: 575px) {
  .dispalyColor {
    flex-direction: column;
  }
  .exBtn {
    color: #fff;
    margin: 10px 0px;
  }
}
